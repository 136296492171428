<script lang="ts">
	import QrCreator from "./components/QRCreator.svelte";
	import QrScanner from "./components/QRScanner.svelte";

	let iframe: HTMLIFrameElement;
	function reloadMap() {
		iframe.src += "#";
		iframe.src = iframe.src.substring(0, iframe.src.length - 1);
	}

	let createDialog: HTMLDialogElement;
	let registerDialog: HTMLDialogElement;
</script>

<main>
	<nav>
		<button on:click={() => createDialog.showModal()}>Create</button>
		<button on:click={() => registerDialog.showModal()}>Register</button>
	</nav>
	<dialog bind:this={createDialog}>
		<QrCreator />
		<button on:click={() => createDialog.close()}>Close</button>
	</dialog>
	<dialog bind:this={registerDialog}>
		<QrScanner
			close={(reload) => {
				registerDialog.close();
				if (reload) reloadMap();
			}}
		/>
	</dialog>

	<section>
		<iframe
			bind:this={iframe}
			title="Map"
			width="100%"
			height="100%"
			frameborder="0"
			allowfullscreen
			src="https://umap.openstreetmap.fr/en/map/msdvil-qr-map_799662"
		/>
	</section>
</main>

<style>
	:global(html, body, #app, main) {
		margin: 0;
		padding: 0;
		width: 100%;
		height: 100%;
	}
	main {
		text-align: center;
		margin: 0 auto;
		display: flex;
		flex-direction: column;
		align-items: stretch;
		justify-content: center;
		overflow-y: hidden;
	}
	nav {
		height: 3rem;
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 0.5rem;
	}
	nav > button {
		padding: 0.5rem 1rem;
		border: 1px solid #ccc;
		border-radius: 0.25rem;
		background: #fff;
		cursor: pointer;
	}
	nav > button:hover {
		background: #eee;
	}
	section {
		flex: 1;
	}
</style>
