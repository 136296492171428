<script lang="ts">
	import { writable, type Writable, get } from "svelte/store";
	import QRCode from "qrcode-svg";
	let existingCodes = [];
	let newCodes = writable([""]);
	let qrList: HTMLDivElement;

	async function getCodes() {
		await fetch("/api/codes")
			.then((res) => res.json())
			.then((data) => {
				existingCodes = data;
			});
	}

	function renderQR(content: string) {
		var qrcode = new QRCode({
			content: "https://msdvil.sexy/" + content,
			join: true,
			container: "svg-viewbox",
			padding: 2,
			width: 256,
			height: 256,
			color: "#000000",
			background: "#ffffff",
			ecl: "H",
		});
		const svg = qrcode.svg();
		return svg;
	}

	async function downloadSVGs() {
		const codes = get(newCodes);
		for (const code of codes.filter((code) => code.length > 0)) {
			const a = document.createElement("a");
			a.href = URL.createObjectURL(new Blob([renderQR(code)], { type: "image/svg+xml" }));
			a.download = code + ".svg";
			a.click();
			await new Promise((resolve) => setTimeout(resolve, 1000));
		}
	}
</script>

<section>
	<h2>Create new codes</h2>
	<button on:click={() => newCodes.update((c) => c.concat(""))}>+</button>
	<div class="code-list">
		{#each $newCodes as code, i}
			<div>
				https://msdvil.sexy/
				<input
					type="text"
					on:input={(e) => ($newCodes[i] = e.currentTarget.value)}
					on:focus={() => qrList.scrollTo({ behavior: "smooth", left: i * 320 })}
				/>
				<button on:click={() => newCodes.update((c) => c.filter((_, idx) => idx !== i))}>-</button>
			</div>
		{/each}
	</div>
	<div bind:this={qrList} class="qr-list carousel">
		{#each $newCodes as codeStore, i}
			<div>
				{@html renderQR($newCodes[i])}
				<span>https://msdvil.sexy/{$newCodes[i]}</span>
			</div>
		{/each}
	</div>

	<button on:click={downloadSVGs}>Download QR Codes</button>
</section>

<style>
	section {
		max-width: 25em;
	}
	h2 {
		display: inline-block;
		font-size: 1.2em;
	}
	div.code-list {
		display: flex;
		flex-direction: column;
		margin-block: 1em;
	}
	div.code-list > div {
		font-family: "Consolas", Courier, monospace;
		display: flex;
	}
	div.code-list > div > input {
		flex: 1;
		min-width: 0;
	}
	.qr-list span {
		font-family: "Consolas", Courier, monospace;
		overflow-wrap: break-word;
	}
	.carousel {
		display: flex;
		flex-direction: row;

		scroll-snap-type: x mandatory;
		overflow-x: scroll;
		overflow-y: hidden;
		scroll-behavior: smooth;
	}
	.carousel > div {
		width: 20em;
		flex-shrink: 0;
		scroll-snap-align: center;
	}
	.carousel > div:first-child {
		margin-inline-start: 2.5em;
	}
	.carousel > div:last-child {
		margin-inline-end: 2.5em;
	}
</style>
