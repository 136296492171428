<script lang="ts">
	import { onMount } from "svelte";
	import QrScanner from "qr-scanner";

	export let close: (relaod: boolean) => void;
	let hasCamera = false;
	QrScanner.hasCamera().then((result) => (hasCamera = result));
	let scanner: QrScanner;
	let scannerRunning = false;
	let qrVideo: HTMLVideoElement;
	let scanResult: string = null;
	let location: { latitude: number; longitude: number } = null;

	function scanCallback(result: string | false) {
		scanResult = result || null;
		if (scanResult?.startsWith("https://")) stopScanner();
	}

	function startScanner() {
		scanResult = null;
		scanner.start();
		scannerRunning = true;
	}

	function stopScanner() {
		scanner.stop();
		scannerRunning = false;
	}

	function getPosition() {
		navigator.geolocation.getCurrentPosition(
			(position) => {
				location = {
					latitude: position.coords.latitude,
					longitude: position.coords.longitude,
				};
			},
			(error) => alert(error.message),
			{ enableHighAccuracy: true }
		);
	}

	async function submitCode() {
		let success = false;
		if (scanResult && location) {
			const res = await fetch(`/${scanResult.split("/").pop()}`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					latitude: location.latitude,
					longitude: location.longitude,
				}),
			});
			if (res.ok) success = true;
		}
		scanResult = null;
		location = null;
		close(success);
	}

	onMount(() => {
		scanner = new QrScanner(qrVideo, (res) => scanCallback(res.data), {
			onDecodeError: (error) => scanCallback(false),
			returnDetailedScanResult: true,
			highlightScanRegion: true,
		});
	});
</script>

<section>
	<h2>Register new QR code</h2>
	{#if !hasCamera}
		<h3>Error: Device doesn't have a camera.</h3>
	{/if}
	<div>
		<label for="code">Code:</label>
		<input type="text" name="code" value={scanResult} disabled />
		<button on:click={scannerRunning ? stopScanner : startScanner}>{scannerRunning ? "Stop" : "Start"} scan</button>
	</div>
	<!-- svelte-ignore a11y-media-has-caption -->
	<video bind:this={qrVideo} style={scannerRunning ? "display:block" : "display:none"} />
	<div>
		<label for="coords">Location:</label>
		<input type="text" name="coords" value={location ? Object.values(location).join(", ") : ""} disabled />
		<button on:click={getPosition}>Get Location</button>
	</div>
	<button on:click={submitCode}>{scanResult && location ? "Submit" : "Cancel"}</button>
</section>

<style>
	section {
		max-width: 25em;
	}
	h2 {
		font-size: 1.2rem;
	}
	section > div {
		display: flex;
	}
	section > div > input {
		flex: 1;
		min-width: 0;
	}
	label {
		width: 4em;
		text-align: left;
	}
	button {
		width: 7em;
	}
	video {
		width: 19em;
		aspect-ratio: 1;
		object-fit: cover;
	}
</style>
